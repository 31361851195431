<template>
  <div class="container">
        <!-- 面包屑 -->
    <BaseBread>
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>技术服务</BaseBreadItem>
    </BaseBread>
    <img src="@\assets\images\tech\techBanner.png" alt="">
    <!--文本分界线 begin-->
     <div class="text-split-line">
      <div class="info">
        <p>一直致力于实时、嵌入式系统的研制领域提供专业的解决方案。</p>
        <p>
          提供业界领先的计算性能，既满足客户严苛的业务要求，又提供高性价比服务。
        </p>
      </div>
    </div>
    <CaseInfo></CaseInfo>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import useStore from "@/store";
import { findAd } from '@/api/home'
import { ref } from 'vue'
import CaseInfo from "./components/case-info.vue";

// // 获取当前路由对象
// const route = useRoute();
// // 获取动态路由参数的name
// const name = route.query.name;

export default {
  name: 'HomeBanner',
  components: { CaseInfo },
  setup () {
    const dataSource = ref([])
    const dataSource2 = ref([])
    findAd("techplatImage2").then(data => {
      dataSource2.value = data.data.list[0]
    })
    return { dataSource2 }
  }
}

</script>

<style scoped lang="less">
.text-split-line {
  background: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  height: 160px;
  padding: 0 80px;
  .info {
    padding-left: 10px;
    flex: 1;
    p {
      &:first-child {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 18px;
      }
      &:last-child {
        color: #999;
        font-size: 16px;
      }
    }
  }
}
</style>
